import request1 from '../util/loginrequest'

// 收藏列表
// export const CollectList = (params) => {
//   return request1({
//     url: '/api/demo/subscribe/collectList',
//     method: 'post',
//     data: {
//       user_id: params.user_id || ''
//     }
//   })
// }
export const CollectList = (params) => {
  return request1({
    url: '/api/demo/subscribe/collectList_new',
    method: 'post',
    data: {
      user_id: params.user_id || ''
    }
  })
}
// 收藏删除
export const CollectDelete = (params) => {
  return request1({
    url: '/api/demo/subscribe/collectDel',
    method: 'post',
    data: {
      title_id: params.title_id || '',
      type: params.type || '',
      user_id: params.user_id || ''
    }
  })
}
